import axios from 'axios';
import React, { useState } from "react";
import CustomPopUp from "../../CustomPopup";
import FormSubmitLoader from "../FormSubmitLoader";

export default function UploadProducerAccounts() {
    const [jsonData, setJsonData] = useState('');
    const [jsonError, setJsonError] = useState('');
    const [apiResponsePopup, setApiResponsePopup] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    // New state for file uploads
    const [files, setFiles] = useState([]);
    const [fileError, setFileError] = useState('');
    const [jsonFileError, setJsonFileError] = useState('');

    function displayFailedRecords(data, message) {

        const messageElements = document.getElementsByClassName('message');
        const container = document.createElement('div');

        const messageText = document.createTextNode(message);

        container.appendChild(messageText);

        const preTag = document.createElement('pre');
        preTag.textContent = JSON.stringify(data, null, 2);

        container.appendChild(preTag);

        Array.from(messageElements).forEach((messageElement) => {
            messageElement.appendChild(container);
        });
    }

    const handleFileSubmit = async (event) => {
        event.preventDefault();
        if (files.length === 0) {
            setFileError('Please select at least one file to upload.');
            return;
        }

        setFileError('');
        const url = `${process.env.REACT_APP_BASE_URL_NODE}/api/users/bulk/files`;
        const formData = new FormData();

        files.forEach(file => formData.append('files', file));

        setIsLoading(true);

        try {
            const response = await axios.post(url, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            });
            if (response?.data?.success) {
                setIsLoading(false);
                setApiResponsePopup(true);
                setResponseMessage("");
                displayFailedRecords(response?.data, 'Result:');
            }
        } catch (error) {
            setIsLoading(false);
            setResponseMessage("");
            setApiResponsePopup(true);
            displayFailedRecords(error.response.data, 'Result:');
        }
    };

    const handleJsonSubmit = async (event) => {
        event.preventDefault();
        if (files.length === 0) {
            setJsonFileError('Please select at least one file to upload.');
            return;
        }

        setJsonFileError('');
        const url = `${process.env.REACT_APP_BASE_URL_NODE}/api/users/bulk/categories`;
        const formData = new FormData();

        files.forEach(file => formData.append('files', file));

        setIsLoading(true);

        try {
            const response = await axios.post(url, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            });
            if (response?.data?.success) {
                setIsLoading(false);
                setApiResponsePopup(true);
                setResponseMessage("");
                displayFailedRecords(response?.data, 'Result:');
            }
        } catch (error) {
            setIsLoading(false);
            setResponseMessage("");
            setApiResponsePopup(true);
            displayFailedRecords(error.response.data, 'Result:');
        }
        setFiles([])
    };

    const handleFileChange = (event) => {
        setFiles([...event.target.files]);
        setFileError('');
    };

    const handleTextSubmit = (event) => {
        event.preventDefault();

        let hasError = false;

        if (!jsonData.trim()) {
            setJsonError('fill the Producer Accounts information');
            hasError = true;
        } else {
            try {
                const parsedData = JSON.parse(jsonData);
                if (!Array.isArray(parsedData) || !parsedData.every(item => typeof item === 'object')) {
                    setJsonError('producer accounts information must be an array of objects');
                    hasError = true;
                } else {
                    setJsonError('');
                }
            } catch (error) {
                setJsonError('producer accounts information is not valid JSON');
                hasError = true;
            }
        }

        if (!hasError) {
            const parsedData = JSON.parse(jsonData);
            const dataArray = Object.values(parsedData);

            let url = `${process.env.REACT_APP_BASE_URL_NODE}/api/users/bulk`

            // submit the request to the database
            submitResponse(url, dataArray);
        }
    };

    const submitResponse = async (url, data) => {
        setIsLoading(true);
        const requestData = {
            'data': data
        };

        let headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
        }

        await axios.post(url, requestData, {
            headers,
            timeout: 300000,
        }).then((response) => {
            console.log("save response", response);
            if (response?.data?.success) {
                setApiResponsePopup(true);
                setIsLoading(false);
                setResponseMessage(JSON.stringify(response?.data, '', 4));
            }
        }).catch((error) => {
            setIsLoading(false);
            setResponseMessage(error);
            setApiResponsePopup(true);
        });
    }

    const handleJsonChange = (event) => {
        setJsonData(event.target.value);
        setJsonError('');
    };

    return (
        <>

            <div className="uploadInformationBlock">
                <h2>Upload Producer Accounts</h2>

                {/* File Upload Form */}
                <form className="bulkFileUpload" onSubmit={handleFileSubmit}>
                    <div className="inputGroup">
                        <label htmlFor="fileUpload">Upload Files:</label>
                        <input
                            id="fileUpload"
                            type="file"
                            multiple
                            onChange={handleFileChange}
                        />
                        {fileError && <p className="error">{fileError}</p>}
                    </div>
                    <button className="submitBtn" type="submit">Upload Text Files</button>
                </form>

                <form className="procedureForm" onSubmit={handleTextSubmit}>
                    <div className="inputGroup">
                        <label htmlFor="jsonData">Enter Producer Accounts information here:</label>
                        <textarea
                            spellCheck="false"
                            id="jsonData"
                            name="jsonData"
                            value={jsonData}
                            onChange={handleJsonChange}
                            rows="10"
                            cols="50"
                            placeholder="Enter Producer Accounts information here"
                        />
                        {jsonError && <p className="error">{jsonError}</p>}
                    </div>
                    <button className="submitBtn" type="submit">Upload Producer Information</button>
                </form>

                <h2>Upload Organization Category and Priority</h2>
                <form className="bulkFileUpload" onSubmit={handleJsonSubmit}>
                    <div className="inputGroup">
                        <label htmlFor="fileUpload">Upload Files:</label>
                        <input
                            id="fileUpload"
                            type="file"
                            multiple
                            onChange={handleFileChange}
                        />
                        {jsonFileError && <p className="error">{jsonFileError}</p>}
                    </div>
                    <button className="submitBtn" type="submit">Upload JSON Files</button>
                </form>


                {apiResponsePopup && (
                    <CustomPopUp
                        isOpen={apiResponsePopup}
                        onClose={() => setApiResponsePopup(false)}
                        popup={{
                            heading: "Upload Result",
                            message: `${responseMessage}`,
                            onClick: () => {
                                setApiResponsePopup(false);
                            },
                        }}
                        multipleButton={false}
                    />
                )}
            </div>
            {isLoading && <FormSubmitLoader />}
        </>
    )
}