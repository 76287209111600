import katex from "katex"
import { showInitials } from "../profiles/shared";

export function getLinkElement(value) {
  return renderMathToString(`\\colorbox{#D2F5CD}{\\textcolor{black}{${value}}}`);
}


export function renderMathToString(value, displayMode = false) {
  return katex.renderToString(value, {
    throwOnError: false,
    displayMode: displayMode,
    trust: true
  })
}
export function formatDate(date, handleDateDifferenceContainer) {
  const wordsToReplace = /(today|yesterday|this|next|tomorrow|last)/gi;
  let count = 1;

  let processedDate = date.replace(wordsToReplace, (match) => {
    const newDate = `<span class="date-diff-tooltip${count}">${match}</span>`;
    count += 1;
    return newDate;
  });

  return `<span class="date-format">${processedDate}</span>`;
}


export function checkboxChecked() {
  return renderMathToString(`\\top`)
}

export function convertToTeX(value) {
  return renderMathToString(value);
}

// export function formatSearchResult(name) {
//   const intial = showInitials(name);
// return renderMathToString(`\\textsf{${intial}}\\ \\vert\\ \\textbf{${name}}`);
//   const renderedName = renderMathToString(` \\textbf{${name}}`);
//   const fomattedName = renderedName.replace(/&nbsp;/g, ' ');
//   console.log(renderedName, 'fomattedName => ', fomattedName);
//   return fomattedName;

// }

function renderName(name) {
  const escapedName = name.replace(/&/g, '\\&');
  return renderMathToString(`\\textbf{${escapedName}}`);
}

// Function to format the rendered name (remove &nbsp; within <span> tags with specified classes)
function formatRenderedName(renderedName) {
  const tempElement = document.createElement('div');
  tempElement.innerHTML = renderedName;
  const spans = tempElement.querySelectorAll('span.mord.textbf');
  spans.forEach(span => {
    span.innerHTML = span.innerHTML.replace(/&nbsp;/g, ' ');
  });

  return tempElement.innerHTML;
}

// Main function to format the search result
export function formatSearchResult(name) {
  const renderedName = renderName(name);
  const formattedName = formatRenderedName(renderedName);
  return formattedName;
}

export function formatOrganizationName(userName, targetElement) {
  const updateBadgeStyling = (node, badgeContent, left, maxWidth) => {
    node.style.setProperty('--user-badge-content', badgeContent);
    node.style.setProperty('--user-badge-left-position', `${left}%`);
    node.style.setProperty('--user-badge-max-width', `${maxWidth}%`);
  }
  const parentNode = targetElement?.parentNode;
  const targetElementHeight = targetElement?.clientHeight;

  if (parentNode?.classList.contains('user-badge')) {
    if (window?.innerWidth > 1349 && window?.innerWidth < 1525) {
        targetElementHeight <= 14 ? updateBadgeStyling(parentNode,'left', 5, 29) : updateBadgeStyling(parentNode,'center', 3, 27);
    } else if (window?.innerWidth > 1524) {
        targetElementHeight <= 14 ? updateBadgeStyling(parentNode,'left', 5, 29) : updateBadgeStyling(parentNode,'center', 3.5, 29);
    }
  }

  // let name = userName;
  // let subs = name.split(' ');
  // const exceptions = ['of', 'at', 'and'];

  // const formattedUserName = subs.map(word => {
  //   if (exceptions.includes(word.toLowerCase())) {
  //     return `<p>${word}</p>`;
  //   } else if (word.toUpperCase() === word) {
  //     return `<span>${word}</span>`
  //   } else {
  //     return `<span>${word.charAt(0).toUpperCase()}</span>${word.substr(1)}`;
  //   }
  // });
  // return `<p>${formattedUserName.join(' ')}</p>`;
  return `<p>${userName}</p>`;
}



/* export function  styleName(value) {
  return renderMathToString(`\\colorbox{#D2F5CD}{\\textcolor{black}{${value}}}`);
}


export function styleDate(value) {
  return renderMathToString(`\\colorbox{#D2F5CD}{\\textcolor{black}{${value}}}`);
}


export function getVericleLine(value) {
  return renderMathToString(`\\colorbox{#D2F5CD}{\\textcolor{black}{${value}}}`);
} */

export const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    if (timeoutId) clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

export const formatTitleForOrganization = (name) => {
  let subs = name.split(' ');
  const exceptions = ['of', 'at', 'and'];

  const formattedUserName = subs.map(word => {
    if (exceptions.includes(word.toLowerCase())) {
      return word;
    } else if (word.toUpperCase() === word) {
      return `<span>${word}</span>`
    } else {
      return `<span>${word.charAt(0).toUpperCase()}</span>${word.substr(1)}`;
    }
  });
  return `${formattedUserName.join(' ')}`;
}

  