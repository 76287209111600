import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from "react";
import CustomPopUp from "../../CustomPopup";
import { arrayDeepCopy } from '../../utils/arrayHelper';
import FormSubmitLoader from "../FormSubmitLoader";
import DisplayCommandTable from '../displayCommandTable/displayCommandTable';
import DisplayDataTable from '../displayDataTable/displayDataTable';

export default function UploadTemporalInformation() {
    const [jsonData, setJsonData] = useState('');
    const [commandData, setCommandData] = useState([]);
    const timezoneData = [{
        id: 1,
        name: 'organization',
        value: 'organization',
    }, {
        id: 2,
        name: 'uploader',
        value: moment.tz.guess(),
    }];
    const [selectedCommand, setSelectedCommand] = useState('');
    const [selectedTimezone, setSelectedTimezone] = useState(timezoneData[0]?.value);
    const [selectedYear, setSelectedYear] = useState(moment().year().toString());
    const [jsonError, setJsonError] = useState('');
    const [apiResponsePopup, setApiResponsePopup] = useState(false);
    const [displayDataTable, setDisplayDataTable] = useState(false);
    const [displayCommandTable, setDisplayCommandTable] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [imageData, setImageData] = useState();
    const [processWithAI, setprocessWithAI] = useState(false);

    useEffect(() => {
        fetchCommands();
    }, [])

    const fetchCommands = async () => {
        let headers = {
            Accept: "application/json",
        }

        try {
            await axios.get(`${process.env.REACT_APP_BASE_URL_NODE}/api/commands`, {
                headers
            }).then((response) => {
                if (response.data.status) {
                    setCommandData(response.data.data);
                    setSelectedCommand(response.data.data[0]?.name);
                }
            })
        } catch (error) {
            console.log("save error", error);
        }
    }

    const handleImageSubmit = (event) => {
        event.preventDefault();
        const url = `${process.env.REACT_APP_BASE_URL_NODE}/api/upload/bulk/files`

        let form = new FormData()
        const imgs = Array.from(imageData)
        imgs.forEach((image) => {
            form.append("files", image)
        })
        form.append('commandName', selectedCommand);
        form.append('year', selectedYear);

        if(selectedTimezone != 'organization')
            form.append('timeZone', selectedTimezone);

        form.append('processWithAI', processWithAI);

        submitResponse(url, form)
    }

    const handleTextSubmit = (event) => {
        event.preventDefault();

        let hasError = false;

        if (!jsonData.trim()) {
            setJsonError('fill the temporal information');
            hasError = true;
        } else {
            try {
                const parsedData = JSON.parse(jsonData);
                if (!Array.isArray(parsedData) || !parsedData.every(item => typeof item === 'object')) {
                    setJsonError('temporal information must be an array of objects');
                    hasError = true;
                } else {
                    setJsonError('');
                }
            } catch (error) {
                setJsonError('temporal information is not valid JSON');
                hasError = true;
            }
        }

        let shouldExit = false;

        if (!hasError) {
            const parsedData = JSON.parse(jsonData);
            const dataArray = Object.values(parsedData);
            dataArray.forEach((item, index) => item.id = `${index + 1}`);
            const storedData = dataArray;
            const data = [];

            dataArray.forEach((item) => {
                if (!item?.identifier || !item?.information || !item?.startDate) {
                    setJsonError('fill all the required fields');
                    shouldExit = true;
                    return
                }

                const id = item?.id ? item?.id : '';
                const startDate = item?.startDate ? item?.startDate : '';
                const endDate = item?.endDate ? item?.endDate : '';
                const startTime = item?.startTime ? item?.startTime : '';
                const endTime = item?.endTime ? item?.endTime : '';
                const identifier = item?.identifier ? item?.identifier : '';
                const information = item?.information ? item?.information : '';
                const link = item?.link ? item?.link : '';
                const source = item?.source ? item?.source : '';
                const important = item?.important ? item?.important : 'N';
                const timezone = item.timezone || 'America/New_York';

                const timestamps = createTimestamps(startDate, endDate, startTime, endTime, timezone);
                const postedOn = timestamps?.postedOn;
                const postedOn2 = timestamps?.postedOn2 ? timestamps?.postedOn2 : '';

                data.push({
                    id: id,
                    identifier: identifier,
                    status: information,
                    postedOn: postedOn,
                    postedOn2: postedOn2 || null,
                    link: link,
                    source: source,
                    important: important,
                    timezone,
                    created_at: Math.floor(new Date() / 1000)
                })
            });

            if (shouldExit) {
                return;
            }

            let url = `${process.env.REACT_APP_BASE_URL}/api/event/add-bulk.php`
            // submit the request to the database
            submitResponse(url, { data }, storedData);
        }
    };

    function createTimestamps(startDate, endDate, startTime, endTime, timezone) {
        function parseTime(date, time) {
            if (date && time) {
                return moment.tz(`${date} ${time}`, 'YYYY-MM-DD h:mmA', timezone).unix();
            } else {
                return moment.tz(date, 'YYYY-MM-DD', timezone).unix();
            }
        }

        let postedOn, postedOn2;

        if (startDate) {
            postedOn = parseTime(startDate, startTime);
        }

        if (endDate) {
            if (endTime) {
                postedOn2 = parseTime(endDate, endTime);
            } else {
                postedOn2 = parseTime(endDate);
            }
        } else if (startDate && endTime) {
            postedOn2 = parseTime(startDate, endTime);
        }

        return { postedOn, postedOn2 };
    }

    const displayFailedRecords = (data, message) => {
        const messageElements = document.getElementsByClassName('message');
        const container = document.createElement('div');

        const messageText = document.createTextNode(message);

        container.appendChild(messageText);

        const preTag = document.createElement('pre');
        preTag.textContent = JSON.stringify(data, null, 2);

        container.appendChild(preTag);

        Array.from(messageElements).forEach((messageElement) => {
            messageElement.appendChild(container);
        });
    }

    const submitResponse = async (url, data, storedData) => {
        setIsLoading(true);
        const requestData = data;

        let headers = {
            Accept: "application/json",
            // "Content-Type": "application/json",
        }

        await axios.post(url, requestData, {
            headers,
            timeout: 300000,
        }).then((response) => {
            if (response?.data?.status && response?.data?.data?.batchId) {
                setIsLoading(false);
                setResponseMessage(`${response?.data?.message}`);
                setApiResponsePopup(true);
                displayFailedRecords([response?.data?.data], 'Batch Progress:');
            }
            if (response?.data?.success) {
                setIsLoading(false);
                // setResponseMessage(`successfullyInserted : ${response?.data?.response?.successfullyInserted}`);
                setApiResponsePopup(true);
                displayFailedRecords(response?.data, 'Result:');
                if (response?.data?.response?.failedRecords?.organizationNotFound?.length > 0) {
                    const failedRecords = response?.data?.response?.failedRecords?.organizationNotFound;
                    let receivedData = [];

                    if (storedData)
                        failedRecords.forEach((item) => {
                            storedData?.forEach((data) => {
                                if (data?.id === item?.id) {
                                    const { id, ...rest } = data;
                                    receivedData.push(rest);
                                    return;
                                }
                            })
                        });
                    else
                        receivedData = arrayDeepCopy(failedRecords)

                    displayFailedRecords(receivedData, 'failedRecords:');
                }
            }
        }).catch((error) => {
            setIsLoading(false);
            setResponseMessage(error);
            setApiResponsePopup(true);
            displayFailedRecords(error.response.data, 'Result:');
        });
    }
    const handleJsonChange = (event) => {
        setJsonData(event.target.value);
        setJsonError('');
    };

    const checkPendingRequest = (e) => {
        e.preventDefault();
        setDisplayDataTable(true);
    }

    const handleViewCommandsClick = (e) => {
        e.preventDefault();
        setDisplayCommandTable(true);
    }

    const handleRefetchCommands = (isSuccess) => {
        if (isSuccess) {
            fetchCommands();
        }
    }

    return (
        <>
            {displayDataTable ? (<DisplayDataTable setDisplayDataTable={setDisplayDataTable} />) :
                displayCommandTable ? (<DisplayCommandTable commandData={commandData} setDisplayCommandTable={setDisplayCommandTable} handleRefetchCommands={handleRefetchCommands} />) : (
                    <div className="uploadInformationBlock">
                        <h2>Upload temporal Inforamtion</h2>

                        <form className='image-uploader' onSubmit={handleImageSubmit} >
                            <div className='flex-between'>
                                {commandData?.length && <div className='inputFieldGroup'>
                                    <label for="cars">Year:</label>
                                    <input type='text' id='year' name='year' value={selectedYear} onChange={(e) => setSelectedYear(e.target.value)} />
                                </div>}
                                {commandData?.length && <div className='selectField'>
                                    <label for="cars">Timezone:</label>
                                    <select name="timezone" id="timezone" value={selectedTimezone} onChange={(e) => setSelectedTimezone(e.target.value)}>
                                        {timezoneData?.length && timezoneData.map((data) => {
                                            return <option key={data?.id} value={data?.value}>{data?.name}</option>
                                        })}
                                    </select>
                                </div>}
                            </div>
                            <div className='flex-between'>
                                {commandData?.length && <div className='selectField'>
                                    <label for="cars">Command:</label>
                                    <select name="command" id="command" value={selectedCommand} onChange={(e) => setSelectedCommand(e.target.value)}>
                                        {/* <option key={null} value={''}>Select</option> */}
                                        {commandData?.length && commandData.map((data) => {
                                            return <option key={data?._id} value={data?.name}>{data?.name}</option>
                                        })}
                                    </select>
                                </div>}
                                <input type="file" name='images' accept=".jpg,.JPG,.jpeg,.JPEG,.png,.PNG,.gif,.GIF,.txt" multiple required onChange={(e) => setImageData(e.target.files)} />
                            </div>
                            <div className='flex-between'>
                                <label htmlFor="processWithAI">
                                    <input type="checkbox" id="processWithAI" name="processWithAI" checked={processWithAI} onChange={(e) => setprocessWithAI(e.target.checked)} />
                                    &nbsp;Process With AI
                                </label>
                            </div>
                            <button className="submitBtn" type="submit">Upload Image/Text File</button>
                        </form>
                        <div className='viewBtnWrapper'>
                            <button className="submitBtn" onClick={(e) => handleViewCommandsClick(e)}>View Commands</button>
                            <button className="submitBtn" onClick={(e) => checkPendingRequest(e)}>View Batches</button>
                        </div>
                        <h6>or</h6>

                        <form onSubmit={handleTextSubmit}>
                            <div className="inputGroup">
                                <label htmlFor="jsonData">Enter temporal information here:</label>
                                <textarea
                                    spellCheck="false"
                                    id="jsonData"
                                    name="jsonData"
                                    value={jsonData}
                                    onChange={handleJsonChange}
                                    rows="10"
                                    cols="50"
                                    placeholder="Enter temporal information here"
                                />
                                {jsonError && <p className="error">{jsonError}</p>}
                            </div>
                            <button className="submitBtn" type="submit">Upload Temporal Information</button>
                        </form>

                        {apiResponsePopup && (
                            <CustomPopUp
                                isOpen={apiResponsePopup}
                                onClose={() => setApiResponsePopup(false)}
                                popup={{
                                    heading: "Upload Result",
                                    message: `${responseMessage}`,
                                    onClick: () => {
                                        setApiResponsePopup(false);
                                    },
                                }}
                                multipleButton={false}
                            />
                        )}
                    </div>
                )}
            {isLoading && <FormSubmitLoader />}
        </>
    )
}
