import axios from 'axios';
import React, { useEffect, useState } from 'react';
import CustomPopUp from "../../CustomPopup";
import FormSubmitLoader from '../FormSubmitLoader';

const DisplayDataTable = ({ setDisplayDataTable }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [batchLoading, setBatchLoading] = useState(false);
    const [batchResponsePopup, setBatchResponsePopup] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const completionIndicatorStatus = ["completed", "failed", "expired", "cancelled"];

    function isCompletionStatus(status) {
        return completionIndicatorStatus.includes(status);
    }

    function noPendingBatches(data) {
        data.every(data => isCompletionStatus(data.status)
            && data.status == "completed" && data.insertion_status && isCompletionStatus(data.insertion_status)
        );
    }

    useEffect(() => {
        let intervalId;

        const fetchData = async () => {
            try {
                await axios.get("https://app.timesort.com/api/batch").then((res) => {
                    const result = res?.data;
                    if (result?.data?.length) {
                        const newData = result.data.reverse();
                        setData(newData);
                        setLoading(false);
                        const data = result.data;
                        if (noPendingBatches(data) && intervalId)
                            clearInterval(intervalId)
                    } else {
                        clearInterval(intervalId);
                        setLoading(false);
                    }
                });
            } catch (error) {
                console.error('Error fetching data:', error);
                clearInterval(intervalId);
                setLoading(false);
            }
        };

        fetchData();
        intervalId = setInterval(fetchData, 15000);

        return () => clearInterval(intervalId);
    }, []);

    const fetchBatchDetails = async (batchId) => {
        setBatchLoading(true);
        try {
            await axios.get(`https://app.timesort.com/api/batch/${batchId}`).then((res) => {
                const batchData = res?.data;
                if (batchData?.data) {
                    setBatchLoading(false);
                    setBatchResponsePopup(true);
                    let data = batchData?.data?.insertion_result;
                    // let compresedFailedData = [];
                    // const failedRequests = batchData?.data?.failed_requests;
                    // failedRequests.map((failedRequest) => {
                    //     if (compresedFailedData.length) {
                    //         compresedFailedData.map((data) => {
                    //             if (data.error === failedRequest.error) {
                    //                 data.filename = [...data.filename, failedRequest.filename];
                    //             } else {
                    //                 compresedFailedData.push({
                    //                     error: failedRequest.error,
                    //                     filename: [failedRequest.filename]
                    //                 })
                    //             }
                    //         })
                    //     } else {
                    //         compresedFailedData.push({
                    //             error: failedRequest.error,
                    //             filename: [failedRequest.filename]
                    //         });
                    //     }
                    // })
                    // data = Object.assign({}, { ...data, 'failedFiles': compresedFailedData });
                    // delete data.response.failedRecords;
                    displayDataToPopup(batchData?.data);
                } else {
                    setBatchLoading(false);
                }
            });
        } catch (error) {
            console.error('Error fetching data:', error);
            setBatchLoading(false);
            setResponseMessage(error);
            setBatchResponsePopup(true);
        }
    }

    const displayDataToPopup = (data, message) => {
        const messageElements = document.getElementsByClassName('message');
        const container = document.createElement('div');

        const preTag = document.createElement('pre');
        preTag.textContent = JSON.stringify(data, null, 2);

        container.appendChild(preTag);

        Array.from(messageElements).forEach((messageElement) => {
            messageElement.appendChild(container);
        });
    }

    return (
        <>
            {loading ? (<FormSubmitLoader />) : (
                <div className='batchFilesWrapper'>
                    <div className="batchFilesBlock">
                        <button className="backButton" onClick={() => setDisplayDataTable(false)}>Go Back</button>
                        <h2>Batch Files</h2>
                        <div className='tableWrapper'>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Batch ID</th>
                                        <th>Total</th>
                                        <th>Complete</th>
                                        <th>Failed</th>
                                        <th>Status</th>
                                        <th>Insertion Status</th>
                                        <th>Insertion Result</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.length ? data.map((obj) => (
                                        <tr key={obj?.batchId}>
                                            <td>{obj?.batchId}</td>
                                            <td>{obj?.image_counts?.total}</td>
                                            <td>{obj?.image_counts?.completed}</td>
                                            <td>{obj?.image_counts?.failed}</td>
                                            <td>{obj?.status}</td>
                                            <td>{obj?.insertion_status}</td>
                                            <td><button disabled={!isCompletionStatus(obj?.status)} className='actionBtn' onClick={() => fetchBatchDetails(obj?.batchId)}>Check Result</button></td>
                                        </tr>
                                    )) : (
                                        <tr>
                                            <td colSpan="7">No data available</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        {batchResponsePopup && (
                            <CustomPopUp
                                isOpen={batchResponsePopup}
                                onClose={() => setBatchResponsePopup(false)}
                                popup={{
                                    heading: "Insertion Result",
                                    message: `${responseMessage}`,
                                    onClick: () => {
                                        setBatchResponsePopup(false);
                                    },
                                }}
                                multipleButton={false}
                            />
                        )}
                    </div>
                </div>
            )}
            {batchLoading && <FormSubmitLoader />}
        </>
    );
};

export default DisplayDataTable;
