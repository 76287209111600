import moment from 'moment';
import { formatDate, weekDays } from '../../components/Timetable/Timetable';
import { convertMinutesToTwoDigit, formatUnixTimestamp } from "../time";

export const getdateformat = (datedata, datedata2, timeZone) => {
    const [date1, abbreviation1] = formatUnixTimestamp(datedata, timeZone);
    const [date2, abbreviation2] = formatUnixTimestamp(datedata2, timeZone);
    const time1 = moment(date1).format("h:mma");
    const time2 = moment(date2).format("h:mma");
    const currentDate = moment(new Date()).format("YYYY-MM-DD");
    const currentYear = moment(currentDate, "YYYY-MM-DD").year();
    const postedDate1 = formatDate(date1, "YYYY-MM-DD");
    const postedDate2 = formatDate(date2, "YYYY-MM-DD");
    const day1 = moment(postedDate1).format('ddd');
    const day2 = moment(postedDate2).format('ddd');
    const postedYear1 = moment(postedDate1, "YYYY-MM-DD").year();
    const postedYear2 = moment(postedDate2, "YYYY-MM-DD").year();
    const durationForDate1 = moment.duration(moment(postedDate1).diff(moment(currentDate)));
    const durationForDate2 = moment.duration(moment(postedDate2).diff(moment(currentDate)));
    const daysDifference1 = durationForDate1.asDays();
    const daysDifference2 = durationForDate2.asDays();
    const isTime1Present = time1 !== "12:00am";
    const isTime2Present = time2 !== "12:00am";
    if (postedDate1 && postedDate2 && postedDate1 === postedDate2) {
        const [formattedDate, isClosestDate] = formatEventDateWithRelativeTime(daysDifference1, date1, postedDate1, time1, isTime1Present, postedYear1, currentYear)
        const timeRegex = /\b\d{1,2}(?::\d{2})?(?:am|pm)?\b/i;
        const sentenceWithoutTime = formattedDate.replace(timeRegex, '').trim();
        const formattedTime = formatTimeRange(time1, time2);
        return `${!isClosestDate ? day1 + ',' : ''} ${sentenceWithoutTime} ${formattedTime}${isTime1Present || isTime2Present ? ' ' + abbreviation1 : ''}${postedYear1 == currentYear ? '' : ', ' + postedYear1}`;
    } else if (date1 && date2 === null || date2 === '') {
        var [formattedDate, isClosestDate] = formatEventDateWithRelativeTime(daysDifference1, date1, postedDate1, time1, isTime1Present, postedYear1, currentYear)
        formattedDate = formattedDate.replace(/,/, ' at');
        return `${!isClosestDate ? day1 + ',' : ''} ${formattedDate}${isTime1Present ? ' ' + abbreviation1 : ''}${postedYear1 == currentYear ? '' : ', ' + postedYear1}`;
    } else {
        var [formattedDate1, isClosestDate1] = formatEventDateWithRelativeTime(daysDifference1, date1, postedDate1, time1, isTime1Present, postedYear1, currentYear)
        var [formattedDate2, isClosestDate2] = formatEventDateWithRelativeTime(daysDifference2, date2, postedDate2, time2, isTime2Present, postedYear2, currentYear)
        formattedDate1 = formattedDate1.replace(/,/, ' at');
        formattedDate2 = formattedDate2.replace(/,/, ' at');
        if (postedYear1 === postedYear2) {
            if (abbreviation1 === abbreviation2) {
                return `from ${!isClosestDate1 ? day1 + ',' : ''} ${formattedDate1}${isTime1Present ? ' ' + abbreviation1 : ''} to ${!isClosestDate2 ? day2 + ',' : ''} ${formattedDate2}${isTime2Present ? ' ' + abbreviation2 : ''}${postedYear2 == currentYear ? '' : ', ' + postedYear2}`;
            }
            return `from ${!isClosestDate1 ? day1 + ',' : ''} ${formattedDate1}${isTime1Present ? ' ' + abbreviation1 : ''} to ${!isClosestDate2 ? day2 + ',' : ''} ${formattedDate2}${isTime2Present ? ' ' + abbreviation2 : ''}${postedYear2 == currentYear ? '' : ', ' + postedYear2}`;
        } else {
            if (abbreviation1 === abbreviation2) {
                return `from ${!isClosestDate1 ? day1 + ',' : ''} ${formattedDate1}${isTime1Present ? ' ' + abbreviation1 : ''}${postedYear1 == currentYear ? '' : ', ' + postedYear1} to ${!isClosestDate2 ? day2 + ',' : ''} ${formattedDate2}${isTime2Present ? ' ' + abbreviation2 : ''}${postedYear2 == currentYear ? '' : ', ' + postedYear2}`;
            }
            return `from ${!isClosestDate1 ? day1 + ',' : ''} ${formattedDate1}${isTime1Present ? ' ' + abbreviation1 : ''}${postedYear1 == currentYear ? '' : ', ' + postedYear1} to ${!isClosestDate2 ? day2 + ',' : ''} ${formattedDate2}${isTime2Present ? ' ' + abbreviation2 : ''}${postedYear2 == currentYear ? '' : ', ' + postedYear2}`;
        }
    }
}

export const formatEventDateWithRelativeTime = (daysDifference, eventDate, postedDate, time, isTimePresent, postedYear, currentYear) => {
    var [hour, min, meridiem] = time.match(/(\d+):(\d+)([APMapm]{2})/).slice(1);
    var date = "";
    let isClosestDate = true;
    if (daysDifference <= -8 || daysDifference > 7) {
        isClosestDate = false;
        const day = formatDate(eventDate, "D");
        const monthAbbreviation = formatDate(eventDate, "MMM");
        const monthMapping = {
            "Mar": "March",
            "Apr": "April",
            "Jun": "June",
            "Jul": "July"
        };
        const month = monthMapping[monthAbbreviation] || monthAbbreviation;
        // var lasttag = "";

        // if (isTimePresent)
            // switch (day % 10) {
            //     case 1:
            //         if (day != 11) {
            //             lasttag = "st";
            //         } else {
            //             lasttag = "th";
            //         }
            //         break;
            //     case 2:
            //         if (day != 12) {
            //             lasttag = "nd";
            //         } else {
            //             lasttag = "th";
            //         }
            //         break;
            //     case 3:
            //         if (day != 13) {
            //             lasttag = "rd";
            //         } else {
            //             lasttag = "th";
            //         }
            //         break;
            //     default:
            //         lasttag = "th";
            //         break;
            // }

        date = `${month} ${day}`;
        if (isTimePresent) {
            date += `, ${min > 0 ? time : hour + meridiem}`;
        }
        return [date, isClosestDate];
    }
    if (daysDifference < -1 && daysDifference > -8) {
        date = "last" + " " + weekDays(moment(postedDate).weekday());
        if (isTimePresent) {
            date += ", " + (min > 0 ? time : hour + meridiem);
        }
        return [date, isClosestDate];
    }
    if (daysDifference < 0 && daysDifference >= -1) {
        date = "yesterday";
        if (isTimePresent) {
            date += `, ${min > 0 ? time : hour + meridiem}`;
        }
        return [date, isClosestDate];
    }
    if (daysDifference >= 0 && daysDifference < 1) {
        date = "today";
        if (isTimePresent) {
            date += `, ${min > 0 ? time : hour + meridiem}`;
        }
        return [date, isClosestDate];
    }
    if (daysDifference >= 1 && daysDifference < 2) {
        date = "tomorrow";
        if (isTimePresent) {
            date += `, ${min > 0 ? time : hour + meridiem}`;
        }
        return [date, isClosestDate];
    }
    if (daysDifference >= 2 && daysDifference < 7) {
        date = "this" + " " + weekDays(moment(postedDate).weekday());
        if (isTimePresent) {
            date += ", " + (min > 0 ? time : hour + meridiem);
        }
        return [date, isClosestDate];
    }
    if (daysDifference == 7) {
        date = "next" + " " + weekDays(moment(postedDate).weekday());
        if (isTimePresent) {
            date += ", " + (min > 0 ? time : hour + meridiem);
        }
        return [date, isClosestDate];
    }
}

export const formatTimeRange = (time1, time2) => {
    const [hour1, minute1] = time1.slice(0, -2).split(':').map(Number);
    const [hour2, minute2] = time2.slice(0, -2).split(':').map(Number);

    const amPm1 = time1.slice(-2);
    const amPm2 = time2.slice(-2);

    const adjustedHour1 = hour1 === 0 ? 12 : hour1;
    const adjustedHour2 = hour2 === 0 ? 12 : hour2;

    const minute1Str = convertMinutesToTwoDigit(minute1)
    const minute2Str = convertMinutesToTwoDigit(minute2)

    if ((amPm1 === 'am' && amPm2 === 'pm') || (amPm1 === 'pm' && amPm2 === 'am')) {
        return `${adjustedHour1}${minute1 != 0 ? ':' + minute1Str : ''}${amPm1}&ndash;${adjustedHour2}${minute2 != 0 ? ':' + minute2Str : ''}${amPm2}`;
    } else if ((adjustedHour1 != adjustedHour2) && ((amPm1 === 'am' && amPm2 === 'am') || (amPm1 === 'pm' && amPm2 === 'pm'))) {
        return `${adjustedHour1}${minute1 != 0 ? ':' + minute1Str : ''}&ndash;${adjustedHour2}${minute2 != 0 ? ':' + minute2Str : ''}${amPm1}`;
    } else {
        return `${adjustedHour1}:${minute1Str}&ndash;${minute2Str}${amPm1}`;
    }
}