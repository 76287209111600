import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
/**
 * 
 * @param {*} param0 
 * @returns 
 */
const VideoPlayer = ({ url, id, updateMarginsOfTimetablesParent, timetableData }) => {
    const [playing, setPlaying] = useState(false); // State for play/pause
    const [progress, setProgress] = useState(0); // State for video progress
    const [duration, setDuration] = useState(0); // State for video duration
    const [currentTime, setCurrentTime] = useState(0); // State for current time
    const [tooltipTime, setTooltipTime] = useState(0); // State for current time
    const playerRef = useRef(null); // Reference to ReactPlayer
    const progressBarRef = useRef(null); // Reference to progress bar
    const videoContainerRef = useRef(null); // Reference to video container

    const [isDragging, setIsDragging] = useState(false); // State for dragging 
    const isYouTubeUrl = url.includes('youtube.com') || url.includes('youtu.be');
    const isShortsUrl = isYouTubeUrl && url.includes('shorts')
    
    // document.querySelectorAll('#movie_player > div.ytp-chrome-top.ytp-show-cards-title').forEach(el=>el.style.display = "none");

    updateMarginsOfTimetablesParent(timetableData.availableEventsOnDates)
    // Helper to check if the URL is a YouTube link

    const playerProps = isYouTubeUrl
        ? {} // Don't add height for YouTube videos
        : { height: '100%' }; // Add height for non-YouTube videos

    const updateDimensions = () => {
        if (!videoContainerRef.current.classList.contains("yt"))
            return
        const containerWidth = videoContainerRef.current.offsetWidth;
        const newHeight = containerWidth / 1.75; // 16:9 aspect ratio
        const maxChildHeight = containerWidth / 1.78; // Example adjustment

        // Apply the calculated heights via CSS variables
        videoContainerRef.current.style.setProperty('--video-container-height', `${newHeight}px`);
        videoContainerRef.current.style.setProperty('--max-video-container-child-height', `${maxChildHeight}px`);
        // videoContainerRef.current.style.height = `${newHeight}px`;
    };

    useEffect(() => {
        // Update dimensions on component mount and window resize
        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        return () => {
            window.removeEventListener('resize', updateDimensions);
        };
    }, []);

    // Toggle play/pause state
    const handlePlayPause = () => {
        setPlaying(!playing);
    };

    // Forward the video by 10 seconds
    const handleForward = () => {
        const currentTime = playerRef.current.getCurrentTime();
        playerRef.current.seekTo(currentTime + 10);
    };

    // Rewind the video by 10 seconds
    const handleRewind = () => {
        const currentTime = playerRef.current.getCurrentTime();
        playerRef.current.seekTo(currentTime - 10);
    };

    const getCleanedUrl = (url) => {
        if (ReactPlayer.canPlay(url)) {
            // if (isYouTubeUrl) {
            //     // Append YouTube parameters to clean up the UI
            //     // setMediaHeight("")
            //     return url //`${url}?controls=0&modestbranding=1&rel=0&iv_load_policy=3&fs=0`;
            // }
            if (url.includes('vimeo.com')) {
                // Append Vimeo parameters to clean up the UI
                return `${url}?title=0&byline=0&portrait=0`;
            }
        }
        return url; // Return the original URL if it's not a video-sharing platform
    };

    // Update the progress state as the video plays
    const handleProgress = (state) => {
        if (!isDragging) {
            setProgress(state.played);
            setCurrentTime(playerRef.current.getCurrentTime());
        }
    };

    // Handle progress bar click
    const handleProgressBarChange = (e) => {
        const newProgress = e.nativeEvent.offsetX / progressBarRef.current.clientWidth;
        setProgress(newProgress);
        playerRef.current.seekTo(newProgress);
    };

    // Handle drag start
    const handleDragStart = (e) => {
        e.preventDefault(); // Prevent default drag behavior
        e.stopPropagation(); // Stop the event from propagating to other elements

        setIsDragging(true);
        window.addEventListener("mousemove", handleDrag);
        window.addEventListener("mouseup", handleDragStop);
    };

    // Handle drag stop
    const handleDragStop = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (!isDragging) return;

        setIsDragging(false);
        const newProgress = Math.min(
            Math.max(e.clientX - progressBarRef.current.getBoundingClientRect().left, 0),
            progressBarRef.current.clientWidth
        ) / progressBarRef.current.clientWidth;
        setProgress(newProgress);
        playerRef.current.seekTo(newProgress);
        setCurrentTime(newProgress);
        window.removeEventListener("mousemove", handleDrag);
        window.removeEventListener("mouseup", handleDragStop);
    };

    // Handle drag movement
    const handleDrag = (e) => {
        if (isDragging) {
            const newProgress = Math.min(
                Math.max(e.clientX - progressBarRef.current.getBoundingClientRect().left, 0),
                progressBarRef.current.clientWidth
            ) / progressBarRef.current.clientWidth;
            setProgress(newProgress);
        }
    };

    const handleDoubleClick = (e) => {
        console.dir({ e }, { depth: 4 });
    };

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = Math.floor(seconds % 60);
        return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
    };

    const ControlBar = () => (
        <div className="controls">
            <div className="control seek" onClick={handleRewind}>
                <img className="img1" src="../../images/Backwards.png" alt="Rewind" />
            </div>
            <div className={`control playback ${playing ? "playing" : "pause"}`} onClick={handlePlayPause}>
                <img className="img2" src={`../../images/${playing ? "pause_new" : "play"}.png`} alt="Play/Pause" />
            </div>
            <div className="control seek" onClick={handleForward}>
                <img className="img3" src="../../images/Forwards.png" alt="Forward" />
            </div>
        </div>
    );

    const ProgressBar = () => (
        <div
            className="progress-container"
        >
            {/* Current time */}
            <span className="current-time" style={{ marginRight: "10px" }}>{formatTime(currentTime)}</span>
            <div
                ref={progressBarRef}
                className="progress-bar"
                onClick={handleProgressBarChange}
            >
                <div
                    className="progress"
                    style={{
                        width: `${progress * 100}%`,
                    }}
                />
                <div
                    className="progress-dot-container"
                    style={{
                        left: `calc(${progress * 100}% - 3.5px)`,
                    }}
                >
                    {/* Tooltip */}
                    <div className="tooltip">
                        {formatTime(currentTime)}
                    </div>
                    {/* Progress Dot */}
                    <div
                        className="progress-dot"
                        onMouseDown={handleDragStart}
                    />
                </div>
            </div>
            {/* Total duration */}
            <span className="duration" style={{ marginLeft: "10px" }}>{formatTime(duration)}</span>
        </div>
    );


    return (
        <div id={id} className="custom-video-player">
            {/* Video Player */}
            <div
                ref={videoContainerRef}
                onDoubleClick={handleDoubleClick}
                onClick={handlePlayPause}
                className={`custom-video-player-video-container ${isShortsUrl ? "yt-shorts" : isYouTubeUrl ? "yt" : ""}`}
            >
                <ReactPlayer
                    ref={playerRef} // Reference to the player instance
                    url={getCleanedUrl(url)} // Video URL from props
                    playing={playing} // Control play/pause state
                    {...playerProps}
                    width="100%"
                    controls={false} // Disable default controls
                    onProgress={handleProgress} // Update progress state
                    onDuration={(dur) => setDuration(dur)} // Set video duration
                    onPlay={() => setPlaying(true)}
                    onPause={() => setPlaying(false)}
                    config={{
                        youtube: {
                            playerVars: {
                                controls: 0, // Hide player controls
                                modestbranding: 1, // Limit YouTube branding
                                rel: 0, // Prevent showing related videos
                                iv_load_policy: 3, // Hide video annotations
                                fs: 0, // Disable fullscreen button
                            },
                        },
                    }}
                // style={{
                //     maxHeight: isYouTubeUrl && !isShortsUrl ? "255px" : ""
                // }}
                />
            </div>

            {/* Progress Bar */}
            <ProgressBar />

            {/* Custom Controls */}
            <ControlBar />

            {/* {children} */}
        </div>
    );
};

export default VideoPlayer;
